<template>
  <div>
    <el-main>
      <el-button type="primary" style="margin-bottom: 10px" size="small" @click="toAdd">+添加优惠券</el-button>
      <!-- 表单 -->
      <el-form class="el-form-search" label-width="140px">
        <el-form-item label="优惠券名称：">
          <el-input size="small" v-model="searchForm.coupon_name" placeholder="请输入优惠券名称"></el-input>
        </el-form-item>
        <el-form-item label="优惠券状态：">
          <el-select v-model="searchForm.status" size="small" placeholder="请选择" clearable>
            <el-option label="全部" :value="0"></el-option>
            <el-option label="停用" :value="-1"></el-option>
            <el-option label="正常" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " label-width="25px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="coupon_name" label="优惠券名称" align="center" min-width="130"></el-table-column>
        <el-table-column prop="coupon_picture" label="优惠券图片" width="100" align="center">
          <template v-slot="{ row }">
            <div class="demo-image__preview">
              <el-image style="width: 100px; height: 50px" :src="row.coupon_picture[0]" :preview-src-list="row.coupon_picture"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="per_consume_full" label="优惠券规则" align="center" width="250">
          <template v-slot="{ row }">
            每消费满{{ row.per_consume_full }}减{{ row.subtract }}
          </template>
        </el-table-column>
        <el-table-column prop="integral" label="兑换积分数" align="center" min-width="120"></el-table-column>
        <el-table-column prop="get_start_time" label="领券时间" align="center" width="200">
          <template v-slot="{ row }">
            <div>{{ getDateformat(row.get_start_time) }}</div>
            <div>{{ getDateformat(row.get_end_time) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="有效期" align="center" width="150">
          <template v-slot="{ row }">
            <div v-if="row.start_time">
              <div>{{ getDateformat(row.start_time) }}</div>
              <div>{{ getDateformat(row.end_time) }}</div>
            </div>
            <div v-if="row.days">
              领取后{{ row.days }}天
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="get_number" label="兑换数量" align="center" min-width="120"></el-table-column>
        <el-table-column prop="use_number" label="使用数量" align="center" min-width="120"></el-table-column>
        <el-table-column label="领券状态" align="center" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.get_status != 0 ? (scope.row.get_status == -1 ? '已结束' : '进行中') : '未开始' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" min-width="120">
          <template slot-scope="scope">
            <el-switch size="small" @change="editChange(scope.row)" v-model="scope.row.status" :active-value="1" :inactive-value="-1"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button @click="remove(scope.row.id, scope.$index)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 翻页 -->
      <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
  </div>
</template>

<script>

import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        coupon_name: '',
        status: 0,
      },
    };
  },
  // 生命周期
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        coupon_name: '',
        status: 0,
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    // 获取列表
    getList() {
      this.$axios.post(this.$api.repair.Coupon.list, this.searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            list[i].coupon_picture = [list[i].coupon_picture];
          }
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toAdd() {
      this.$router.push('/marketing/repairAddCoupon');
    },
    editChange (row) {
      this.$axios.post(this.$api.repair.Coupon.editStatus, {
        id: row.id,
        status: row.status,
      })
      .then(res => {
        if (res.code == 0) {
          this.$message.success('保存成功');
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    remove(id, index) {
      this.$confirm('确认删除此优惠券？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.repair.Coupon.del, {
            id: id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
              });
              this.list.splice(index, 1);
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
